.table-formation {
    margin-top: 30px
}

.table-formation thead tr td,
.table-formation thead tr th {
    padding: 0 20px 20px;
    vertical-align: top;
    color: $primary-color;
    font-family: Karbon, verdana, helvetica, arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    background-color: hsla(0, 0%, 100%, .8);
    border-bottom: 0;
    border-left: 1px solid #d1d1d1
}

.table-formation thead tr td:empty,
.table-formation thead tr th:empty {
    border-left: 0
}

.table-formation thead .fa,
.table-formation thead .icon {
    display: block;
    margin-bottom: 10px;
    font-size: 1.75em
}

.table-formation tbody tr td,
.table-formation tbody tr th {
    padding: 20px;
    min-width: 120px;
    background-color: #f3f5f7;
}

.table-formation tbody tr .separator {
    padding: 0;
    height: 30px;
    background-color: #fff;
    border-top: 0
}

.table-formation tbody .ttl {
    font-family: Karbon, verdana, helvetica, arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    color: #1e1e1e
}

.table-formation tbody .ttl a {
    position: relative;
    padding-left: 22px;
    display: block;
    color: #1e1e1e
}

.table-formation tbody .ttl a:before {
    content: "\203a";
    display: inline-block;
    font-family: Karbon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: -5px;
    left: 0;
    font-size: 1.375em;
    color: $primary-color
}

.table-formation tbody .ttl a:before.fa-pull-left {
    margin-right: .3em
}

.table-formation tbody .ttl a:before.fa-pull-right {
    margin-left: .3em
}

.table-formation tbody .ttl a:before.pull-left {
    margin-right: .3em
}

.table-formation tbody .ttl a:before.pull-right {
    margin-left: .3em
}

.table-formation tbody .ttl a:focus,
.table-formation tbody .ttl a:hover {
    color: $primary-color;
    text-decoration: none
}

.table-formation tbody .header-ttl {
    font-size: 2em;
    color: #1e1e1e;
    font-family: Karbon, verdana, helvetica, arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    border-top: 0
}
.table-formation thead i.icon-transdev {
    display: block;
    margin-bottom: 10px;
    &:before {
        font-size: 1.75em;
    }
}
.table-formation>thead:first-child>tr:first-child>td, .table-formation>thead:first-child>tr:first-child>th{
    border-top: 0;
}
.table-formation span.new-tag{
    &:before{
        content: 'Nouveau';
        display: inline-block;
        padding: 5px 8px;
        font-size: 1rem;
        font-family: $font-family-base;
        font-weight: 600;
        text-transform: uppercase;
        background-color: $primary-color;
        color: $white;
        line-height: 1;
        color: #fff;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        border-radius: .25em;
        margin-left: 1rem;
    }
}