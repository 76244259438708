@media print {
    .autoblock-horizontal,
    .fixed-top,
    .nav-outermost-wrapper,
    .download-pdf-block,
    #footer,
    .navbar-toggler,
    .btn-search,
    #bb-profiler-panel {
        display:none!important;
    }

    .breadcrumb>li {
        display: inline-block;
    }

    .bb-site-wrapper {
        padding-left: 0;
    }

    .row-item {
        display: inline-block;
    }

    p:empty {
        display: none;
    }

    .article-title h1 {
        font-size: 32px;
    }

    .col-lg-8 {
        width: 65%;
        float: left;
    }

    .col-lg-4 {
        width: 35%;
        float: left;
    }

    .bb-content.is-spacer,
    .cloud-spacer {
        display: none !important;
    }

    .bb-content.is-spacer + .bb-content .col-lg-8 {
        width: 100%;
    }

    .block-formation-info ul {
        display: block!important;
        list-style-type: none;
        overflow: hidden;
    }

    .block-prerequisites {
        margin-top: 50px;
    }

    .block-formation-info ul {
        padding: 0;
    }

    .block-formation-info ul li {
        float: left;
        width: 20%;
        display: table;
    }

    .block-formation-info ul li i,
    .block-formation-info ul li i + div {
        display: table-cell;
    }

    .block-formation-info ul li i + div {
        padding-left: 8px !important;
    }

    .navbar-header {
        display: block!important;
        width: 100%;
        margin-left: 0!important;
    }

    .navbar-header>.container-fluid {
        display: block!important;
        width: 100%;
    }

    .fixed-top {
        position: relative!important;
    }

    .block-evaluation .d-flex,
    .block-pedagogy .d-flex,
    .block-objectives .d-flex,
    .block-prerequisites .d-flex {
        display: table;
        width: 100%;
    }

    .block-evaluation h2,
    .block-pedagogy h2,
    .block-objectives h2,
    .block-prerequisites h2 {
        display: table-cell;
        font-size: 22px;
        width: 80%;
        vertical-align: middle;
        height: 36px !important;
    }

    .block-evaluation i,
    .block-pedagogy i,
    .block-objectives i,
    .block-prerequisites i {
        display: block;
        width: 36px !important;
        height: 36px !important;
        background-repeat: no-repeat;
        background-position: contain;
        background-size: 28px !important;
        text-align: right;
    }

    .bb-site-wrapper .main {
        padding: 0 0 0 0 !important;
    }

    .collapse:not(.show) {
        display: block !important;
    }

    h6.panel-title a {
        background-color: red!important;
        color: #fff!important;
    }

    .navbar-secondary {
        display: none!important;
    }

    .has-bg-color.color-15645791767367{
        display:none !important;
        background-color: transparent!important;
    }

    .bb-content {
        background-image: none!important;
    }

    .block-formation-banner {
        padding: 0!important;
        background-color: transparent!important;
        min-height: 0px!important;

        &:before {
            display: block !important;
            float: left;
            margin-right: 20px;
        }
    }

    .block-formation-banner {
        p,
        strong,
        span {
            color: #1e1e1e!important;
        }
    }

    .cloudcontentset {
        padding-top: 8px !important;
        padding-bottom: 8px !important;
    }
}