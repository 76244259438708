// Head
.navbar-header .navbar-brand {
    height: 60px;
    flex-shrink: 0;
}

.navbar-header {
    z-index: 1;
    @include media-breakpoint-up(lg) {
        height: 100px !important;
    }
}

#navbar-primary {
    height: 60px;

    .btn-menu-search {
        background-color: rgba(0, 0, 0, .2);
        color: white;

        &:hover {
            background-color: rgba(0, 0, 0, .3);
        }
    }

    .navbar-nav {
        flex-wrap: nowrap;
    }

    .nav-link {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        text-decoration: none;
        height: 100%;

        &:after {
        content: '';
            position: absolute;
            top: auto;
            bottom: -15px;
            left: 0;
            right: 99%;
            display: inline-block;
            height: 4px;
            background-color: transparent;
            opacity: 0;
            transition: all .36s ease-out;
        }

        i {
            font-size: 1.5rem;
            margin-bottom: 5px;
        }

        span {
            font-size: 12px;
            line-height: 1.1;
            text-align: center;
            font-weight: 500;
        }
    }

    .logo-formation {
        width: 175px;
    }

    .btn-search {
        font-size: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.navbar-transdev {
    flex-wrap: nowrap;
    flex-direction: row;

    li:not(:last-child) {
        a {
            border-right: 1px solid rgba($white, .5);
        }
    }

    li {
        margin: 0 !important;
    }

    .nav-link {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        text-decoration: none;
        height: 100%;

        &:after {
        content: '';
            position: absolute;
            top: auto;
            bottom: -15px;
            left: 0;
            right: 99%;
            display: inline-block;
            height: 4px;
            background-color: transparent;
            opacity: 0;
            transition: all .36s ease-out;
        }

        i {
            font-size: 1.5rem;
            margin-bottom: 5px;
        }

        span {
            font-size: 12px;
            line-height: 1.1;
            text-align: center;
            font-weight: 500;
        }
    }
}
// Menu head
.navbar-header {
    &, & .navbar-brand {
        height: 80px !important;
    }
}

@include media-breakpoint-down(md) {
    .navbar-header {
        &, & .navbar-brand {
            height: 60px !important;
        }
    }
}

.navbar-primary {
    .navbar-nav {
        color: $white;
    }

    .nav-item.active {
      i, span {
        color: $primary-color !important;
      }
    }
}

.navbar-secondary {
    position: fixed !important;
    width: 210px;
    height: calc(100vh - 60px);
    bottom: 0;
    left: 0;
    @include transition($default-transition-ui);

    @include media-breakpoint-up(lg) {
        height: calc(100vh - 80px);
    }

    .menu-closed & {
        left: -210px;
    }

    .menu-title {
        padding: 1rem;
        border-bottom: 2px solid;
    }

    .fix-height {
        overflow-y: auto;
        border-right: 1px solid;
    }

    .nav-item {
        padding: 1rem;
        border-bottom: 1px solid rgba($white, .4);

        .nav-link {
            color: $white;
            font-size: 17px;
            font-weight: bold;

            &:hover {
              color: rgba($white, .8);
            }
        }
    }

    .menu-handle {
        position: absolute;
        left: 210px;
        top: 50%;
        background: $white;
        padding: 18px 10px;
        transform: translateY(-50%);
        box-shadow: 1px 1px 5px 1px rgba(0,0,0,.45);
        font-size: 1.25rem;
        color: $primary-color;
        cursor: pointer;
    }
}

#navbar-primary {
    height: unset;

    @include media-breakpoint-up(md) {
        height: 100%;
        overflow: hidden;
    }

    .nav-link {
        padding-top: 12px;
        padding-bottom: 10px;
    }
}

.bb-site-wrapper {
    .main {
        padding-left: 210px;
        @include transition($default-transition-ui);

        @include media-breakpoint-up(lg) {
            padding-top: 80px;
        }

        &.menu-closed {
            &,
            & + .footer {
                padding-left: 0;
            }
        }
    }

    .footer {
        padding-left: 210px;
        @include transition($default-transition-ui);
    }

}

.btn-menu-toggle {
    font-size: 18px;
    display: inline-flex;
    transition: all .15s linear;

    &:hover {
      text-decoration: none;
      color: rgba($white, .9);
    }

    &:focus {
      color: $white;
      outline: none;
    }
}

#nav-secondary ul {
    max-height: calc(100vh - 123px);
    overflow-y: auto;
    overflow-x: hidden;
    flex-wrap: wrap;

    &::-webkit-scrollbar {
        width: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: rgba($white, .2);
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $white;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: darken($white, .2);
    }
}

.search-btn i {
    color: #fff !important;
}

#navbar-primary .btn-search {
    background-color: $primary-color !important;
}
